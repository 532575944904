import React from 'react'
import IntegrationTemplate from '../../components/Integrations/IntegrationTemplate'

import dnnsoftwareLogo from '../../images/integration-logo-images/dnnsoftware.png'

const Dnnsoftware = () => {
    return (
        <>
            <IntegrationTemplate 

                metaTitle="Integrate Optinly with DNN Software"
                metaDescription="Optinly is the smart pop-up builder for your DNN Software store. With Optinly, you can seamlessly attract leads to grow your email list and reduce cart abandonment."

                otherProductLogo={dnnsoftwareLogo} //PLEASE IMPORT THE LOGO HERE LIKE THIS

                integrationApp="DNN Software"
                homeTitle="Grow Your Email List with DNN Software Popups"
                homeDescription="Integrate Optinly with your DNN Software store. Ideal lead generation tool with visitor engaging popups to grow your email list. Increase Sales with smart popups."

                docsTitle="Increase your DNN Software store email list  "
                docsDescription="Best DNN Software popup app. A complete package to rapidly grow your email list, leads and subscribers. Select from pre-made popups or custom popup in your DNN Software store.  "

                featruesDocsSection1="Integeration and Customization "
                featruesDocsTitle1="Seamless Integeration and Advance Customization Options"
                featruesDocsDescription1="Customize popups based on user behavioural pattern. Design or development skill not required to customize popup. DNN Software email popup through simple integeration.  "

                featruesDocsSection2="Installation and Signup"
                featruesDocsTitle2 ="DNN Software Popups To Improve Store Performance"
                featruesDocsDescription2="Hassle-free signup, download, and installation. Best DNN Software popup form to deliver customer-friendly popup. Select the best email popup for DNN Software from numerous existing templates."

                featruesDocsSection3="Achieve Marketing Goals "
                featruesDocsTitle3 ="One-stop Solution for DNN Software Popup building"
                featruesDocsDescription3="Everything you need to create user engaging popups in one place. Advacned dashboard, simple integeration, regular update of pre-made templates and a clear user interface."

                featruesDocsSection4="Detailed Dashboard Analytics"
                featruesDocsTitle4 ="All-in-one Dashboard"
                featruesDocsDescription4="Know how your DNN Software Popup is performing. Manage campaigns, make changes, and more with a powerful dashboard. Simple to navigate yet highly accurate analytics available."

                FAQQuestion1="Does Optinly offer DNN Software email popup?  "
                FAQAnswer1="Optinly offers multiple redaymade DNN Software email popups. Also, it provides options to easily customize the popup depending upon the user. DNN Software email popups are popups used in DNN Software websites for collecting quality customer email address.  "

                FAQQuestion2="How many types of DNN Software popup form is available in Optinly?"
                FAQAnswer2="Optinly offers 6+ types of unique popup forms for DNN Software. Each one is engaging and grabs audience attentation without any delay. "

                FAQQuestion3="Is there a free pricing plan available in Optinly for DNN Software stores? " 
                FAQAnswer3="Optinly have a free pricing plan that works on DNN Software stores. Feel free to check out the entire pricing structure in Optinly pricing page.  "

                FAQQuestion4="Is DNN Software exit popup available in Optinly? "
                FAQAnswer4="Yes, Optinly have customer engaging exit popup templates. You can personalize the template or made use of the pre-made one. "
            />
        </>
    )
}

export default Dnnsoftware
